import i18n from './i18n/index'
// 任务类型
export const TASK_TYPE_IMAGE_TASK = 1201;//图片任务
export const TASK_TYPE_UNBIND_TASK = 1202;//解绑
export const TASK_TYPE_LED_TASK = 1203;//LED任务
export const TASK_TYPE_SWITCH_PAGE_TASK= 1204;//切页任务
export const TASK_TYPE_NFC_TASK = 1205;//NFC任务
export const TASK_TYPE_SWITCH_LAYOUT_TASK = 1206; //切换布局
export const TASK_TYPE_SWITCH_TEMPLATE_TASK = 1207;//切换模板
export const TASK_TYPE_FORCE_TRANSMIT_TASK = 1208;//重推
export const TASK_TYPE_BIND_TASK = 1209;//绑定

// 任务执行状态
export const TASK_STATE_INIT = '1901';//任务初始化中
export const TASK_STATE_WAIT_EXPECT = '1902';//任务等待执行
export const TASK_STATE_INPROGRESS = '1903';//任务执行中
export const TASK_STATE_PART_SUCCESS = '1904';//任务执行部分成功
export const TASK_STATE_SUCCESS = '1905';//任务执行全部成功
export const TASK_STATE_ERROR = '1906';//任务执行失败

// 基站连接状态(连接正常，正在连接)
export const COOR_STATE_CONNECTED = '901'
export const COOR_STATE_CONNECTING = '902'
// 价签状态
export const ESLE_STATE_UNACTIVE = '1001'
export const ESLE_STATE_ACTIVE = '1002'
export const ESLE_STATE_UPDATING = '1003'
export const ESLE_STATE_FAILED = '1004'
export const ESLE_STATE_SUCCESS = '1005'
//重推类型
export const transmitSelectTypeList = ()=> {
    return [
        {
            name: i18n.t('devices.esl.bySelected'),
            value: 'Selected'
        },
        {
            name: i18n.t('devices.esl.byAp'),
            value: 'AP'
        },
        {
            name: i18n.t('devices.esl.byEslType'),
            value: 'ESL'
        },
        
    ]
}
export const storeTypeList = () => {
    return [
        {   
            value: 2, 
            name: i18n.t('ThisStore')
        },
        { 
            value: 1, 
            name: i18n.t('ZimenStore')
        },
        {   value: 0, 
            name: i18n.t('AllStore')
        },
    ]
}
// 导入类型列表
export const ImportTypeList = [
    {
        label: 'excel',
        name: 'Excel'
    },
]

// 过滤条件列表
export const characterTypeList = { 
    'text': [
        {id: 'in', name: i18n.t('Contain'),},
        {id: 'eq', name: i18n.t('Equal')},
        {id: 'nl', name: i18n.t('IsEmpty')},
        {id: 'nn', name: i18n.t('NotEmpty')}
    ],
    'number': [
        {id: 'gt', name: '>'},
        {id: 'ge', name: '>='},
        {id: 'lt', name: '<'},
        {id: 'le', name: '<='},
        {id: 'eq', name: '='},
        {id: 'ne', name: '!='}
    ],
    'date': [
        {id: 'gt', name: '>'},
        {id: 'ge', name: '>='},
        {id: 'lt', name: '<'},
        {id: 'le', name: '<='},
        {id: 'eq', name: '='},
        {id: 'ne', name:'!='}
    ],
    'checkbox':[
        {id: 'in', name: i18n.t('Contain')}
    ]
}

// 分页枚举
export const paginationEnum= [10, 20, 50, 100, 200, 500, 1000]

// 页码
export const pageNumberList = [
    {
        dictValue: "1",
        dictName: i18n.t('taskManagerInfo.page1'),
      },
      {
        dictValue: "2",
        dictName: i18n.t('taskManagerInfo.page2'),
      },
      {
        dictValue: "3",
        dictName: i18n.t('taskManagerInfo.page3'),
      },
      {
        dictValue: "4",
        dictName: i18n.t('taskManagerInfo.page4'),
      },
      {
        dictValue: "5",
        dictName: i18n.t('taskManagerInfo.page5'),
      },
]