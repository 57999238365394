import router from "../axios";
import config from "../../config/index"
const { baseUrl } = config;

function getStoreApList(data) {
  // 取门店基站列表
  return router({
    url: baseUrl+"coor/v1/getStoreApList",
    data: data,
    method: "POST",
    header:{
        'Content-Type':'application/json'
    }
  });
}
// 更新基站信息  通过id进行基站信息的更新
function coorUpdate(data) {
  return router({
    url: baseUrl+"coor/v1/update",
    data: data,
    method: "POST",
    header:{
        'Content-Type':'application/json'
    }
  });
}
//获取基站连接记录
function getRecordList(data) {
  return router({
    url: baseUrl+"coor/v1/getRecordList",
    data: data,
    method: "POST",
    header:{
        'Content-Type':'application/json'
    }
  });
}
//批量逻辑删除基站信息  
function batchDelete(data) {
  return router({
    url: baseUrl+"coor/v1/batchDelete",
    data: data,
    method: "POST",
    header:{
        'Content-Type':'application/json'
    }
  });
}

//获取code列表
function getCodeList(data) {
  return router({
    url: baseUrl+"code/v1/getList",
    data: data,
    method: "POST",
    header:{
        'Content-Type':'application/json'
    }
  });
}

//获取门店所有基站列表
function getStoreAllApList(data) {
  return router({
    url: baseUrl+"coor/v1/getStoreAllApList",
    data: data,
    method: "POST",
    header:{
        'Content-Type':'application/json'
    }
  });
}
export {
  getStoreApList,
  coorUpdate,
  getRecordList,
  batchDelete,
  getCodeList,
  getStoreAllApList
}