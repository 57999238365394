<template>
  <div>
    <div class="communication-records_top">
      <span> {{ $t("devices.records") }}: {{ pageParams.total }} </span>
      <el-select
        v-model="pageParams.coorState"
        :placeholder="$t('devices.aps.type')"
        size="small"
        clearable
        class="type-select"
        @change="getList"
      >
        <el-option
          v-for="(item, i) in coorStateList"
          :key="i"
          :label="item.codeName"
          :value="item.codeId"
        />
      </el-select>
      <el-date-picker
        v-model="pageParams.date"
        type="daterange"
        :start-placeholder="$t('devices.selectStartDate')"
        :end-placeholder="$t('devices.selectEndDate')"
        size="small"
        placement="bottom-start"
        @change="handleDateChange"
      >
      </el-date-picker>
    </div>
    <el-table
      border
      :data="tableData"
      style="width: 100%"
      v-loading="tableLoading"
      @sort-change="handleSort"
    >
      <el-table-column
        align="center"
        width="55"
        type="index"
        :label="$t('devices.aps.No')"
      ></el-table-column>
      <el-table-column
        align="center"
        width="180"
        prop="coorName"
        :label="$t('devices.aps.APName')"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        align="center"
        width="180"
        prop="macAddress"
        :label="$t('devices.aps.maAddress')"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        align="center"
        width="180"
        prop="ipAddr"
        :label="$t('devices.aps.iPAddress')"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        width="180"
        align="center"
        prop="coorState"
        :label="$t('devices.aps.status')"
        sortable="custom"
      >
        <template slot-scope="scope">
          <template>
            <span
              class="coor-state"
              :class="{
                'state-connected': scope.row.coorState == COOR_STATE_CONNECTED,
                'state-connecting':
                  scope.row.coorState == COOR_STATE_CONNECTING,
              }"
            >
              {{ scope.row.coorStateDesc }}
            </span>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="120"
        prop="createUser"
        :label="$t('devices.aps.updateBy')"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        width="180"
        align="center"
        prop="createTime"
        :label="$t('devices.aps.updateTime')"
        sortable="custom"
      ></el-table-column>
    </el-table>
    <pagination :tableInfo="pageParams" @changePage="changePage" />
  </div>
</template>
<script>
import moment from "moment";
import { debounce } from "lodash";
import { getRecordList } from "@/libs/api/aps";
import {
  COOR_STATE_CONNECTED,
  COOR_STATE_CONNECTING,
  paginationEnum,
} from "@/libs/enum";
import pagination from "@/components/pagination";
export default {
  components: {
    pagination
  },
  changePage() {
    this.getList();
  },
  props: {
    options: {
      type: Object,
      require: true,
      default: () => {},
    },
    coorStateList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  name: "CommunicationRecords",
  data() {
    return {
      paginationEnum,
      moment,
      COOR_STATE_CONNECTED,
      COOR_STATE_CONNECTING,
      pageParams: {
        current: 1,
        size: 10,
        total: 0,
        date: "",
        startDate: "",
        endDate: "",
        coorState: "",
        orders: [],
      },
      tableData: [],
      tableLoading: false,
    };
  },
  methods: {
     handleSort(column, prop, order){
      console.log('表格排序',column, prop, order)
      let propNewe = ''
      if(column.prop == 'coorName') {
        propNewe = 'coor_name'
      } else if(column.prop == 'macAddress') {
        propNewe = 'mac_address'
      }else if(column.prop == 'ipAddr') {
        propNewe = 'ip_addr'
      }else if(column.prop == 'coorState') {
        propNewe = 'coor_state'
      }else if(column.prop == 'createUser') {
        propNewe = 'create_user'
      }else if(column.prop == 'createTime') {
        propNewe = 'create_time'
      }
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ propNewe;
        arr.push(str1)
      } else {
        arr = []
      }
      
      this.pageParams.orders = arr;
      this.getList()
    },
    changePage() {
      this.getList();
    },
    handleDateChange(val) {
      if (val) {
        this.pageParams.startDate = moment(val[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.pageParams.endDate = moment(val[1]).format("YYYY-MM-DD HH:mm:ss");
        this.pageParams.date = val;
      } else {
        this.pageParams.startDate = "";
        this.pageParams.endDate = "";
      }
      this.getList();
    },
    getList: debounce(async function () {
      const _this = this;
      const params = {
        coorId: this.options.coorId,
        startDate: _this.pageParams.startDate,
        endDate: _this.pageParams.endDate,
        coorState: _this.pageParams.coorState,

        current: _this.pageParams.current,
        size: _this.pageParams.size,
        orders: _this.pageParams.orders,
      };
      _this.tableLoading = true;
      try {
        const { data } = await getRecordList(params);
        if (data.state === true) {
          let dataNew = data.data || {};
          _this.tableData = dataNew.records || [];
          _this.pageParams.total = dataNew.total || 0;
          _this.tableLoading = false;
        } else {
          this.$message.error(data.message);
          _this.tableLoading = false;
        }
      } catch (err) {
        const { message } = err;
        _this.$message.error(message);
        _this.tableLoading = false;
      }
    }, 1000),
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.communication-records_top {
  margin-bottom: 20px;
  .record-text {
    font-size: 16px;
    color: #999999;
  }
  .type-select {
    margin: 0 10px;
  }
}
.coor-state {
  padding: 3px 18px;
  text-align: center;
  border-radius: 15px;
  display: inline-block;
}
.state-connected {
  background: rgba(217, 250, 232, 1);
}
.state-connecting {
  background: rgba(193, 233, 255, 1);
}
</style>