<template>
  <div class="aps-page">
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("devices.Welcome")
      }}</router-link>
      > {{ $t("devices.Devices") }} >
      <span style="color: #3461ff">{{ $t("devices.APs") }}</span>
    </div>
    <!-- <div class="card-bg aps-page_top">
      <el-button
        @click="handelDeleteAll"
        class="aps-page_top-btn"
        icon="el-icon-delete"
        >Batch Reset</el-button
      >
    </div> -->
    <div class="card-bg aps-page_content">
      <div class="aps-page_content-top">
        <span>{{ $t("devices.records") }}: {{ pageParams.total }}</span>
        <el-select
          v-model="pageParams.storeType"
          placeholder=""
          size="small"
          @change="getList"
        >
          <el-option
            v-for="(item, i) in storeTypeList()"
            :key="i"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="aps-page_content-table">
        <el-table
          :data="tableData"
          style="width: 100%; margin-bottom: 20px"
          @selection-change="handleSelectionChange"
          row-key="coorId"
          v-loading="tableLoading"
          @sort-change="handleSort"
        >
          <el-table-column align="center" type="selection">
            <el-table-column
              align="center"
              width="55"
              type="selection"
              style="display: none"
            >
            </el-table-column>
          </el-table-column>
           <!-- sortable="custom" -->
            <!-- prop="store_name" -->
          <el-table-column
            min-width="200"
            align="center"
            :label="$t('devices.aps.storeName')"
           
          >
            <el-table-column min-width="200" align="center" prop="storeName">
              <template slot="header" slot-scope="scope">
                <el-input
                  v-model="pageParams.storeName"
                  @input="getList"
                  size="small"
                  placeholder=""
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            min-width="200"
            align="center"
            :label="$t('devices.aps.APName')"
            sortable="custom"
            prop="coor_name"
          >
            <el-table-column min-width="200" align="center" prop="coorName">
              <template slot="header" slot-scope="scope">
                <el-input
                  v-model="pageParams.coorName"
                  @input="getList"
                  size="small"
                  placeholder=""
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            min-width="200"
            align="center"
            :label="$t('devices.aps.ip')"
            sortable="custom"
            prop="ip_addr"
          >
            <el-table-column min-width="200" align="center" prop="ipAddr">
              <template slot="header" slot-scope="scope">
                <el-input
                  v-model="pageParams.ipAddr"
                  @input="getList"
                  size="small"
                  placeholder=""
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            min-width="200"
            align="center"
            :label="$t('devices.aps.status')"
            sortable="custom"
            prop="coor_state"
          >
            <el-table-column min-width="200" align="center" prop="coorState">
              <template slot="header" slot-scope="scope">
                <el-select
                  v-model="pageParams.coorState"
                  placeholder=""
                  size="small"
                  clearable
                  @change="getList"
                >
                  <el-option
                    v-for="(item, i) in coorStateList"
                    :key="i"
                    :label="item.codeName"
                    :value="item.codeId"
                  />
                </el-select>
              </template>
              <span slot-scope="scope" width="300">
                <template>
                  <span
                    class="coor-state"
                    :class="{
                      'state-connected':
                        scope.row.coorState == COOR_STATE_CONNECTED,
                      'state-connecting':
                        scope.row.coorState == COOR_STATE_CONNECTING,
                    }"
                  >
                    {{ scope.row.coorStateDesc }}
                  </span>
                </template>
              </span>
            </el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            :label="$t('devices.aps.lastCommunication')"
            min-width="200"
            sortable="custom"
            prop="coor_time"
          >
            <el-table-column min-width="200" align="center" prop="coorTime">
              <template slot="header" slot-scope="scope">
                <el-date-picker
                  v-model="pageParams.date"
                  type="daterange"
                  start-placeholder=""
                  end-placeholder=""
                  size="small"
                  @input="handleDateChange"
                  placement="bottom-start"
                  style="width: 90%"
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            min-width="200"
            :label="$t('devices.aps.labelCount')"
            sortable="custom"
            prop="bind_tags"
          >
            <el-table-column align="center" min-width="200" prop="bindTags">
              <template slot="header" slot-scope="scope">
                <el-input
                  v-model="pageParams.startTags"
                  @input="getList"
                  size="small"
                  placeholder=""
                  style="width: 40%"
                  clearable
                  type="number"
                />
                -
                <el-input
                  v-model="pageParams.endTags"
                  @input="getList"
                  size="small"
                  placeholder=""
                  style="width: 40%"
                  clearable
                  type="number"
                />
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            min-width="200"
            :label="$t('devices.aps.remark')"
            sortable="custom"
            prop="remark"
          >
            <el-table-column align="center" min-width="200" prop="remark">
              <template slot="header" slot-scope="scope">
                <el-input
                  v-model="pageParams.remark"
                  @input="getList"
                  size="small"
                  placeholder=""
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            align="center"
            fixed="right"
            :label="$t('devices.aps.action')"
            width="130"
          >
            <el-table-column min-width="200" align="center" width="130">
              <template slot-scope="{ row }">
                <div class="action-wrapper">
                  <span class="icon icon-info" @click="handelView(row)">
                    <!-- <icon-svg name="bz-info"></icon-svg> -->
                    <i class="el-icon-tickets"></i>
                  </span>
                  <span class="icon" @click="handelEdit(row)">
                    <icon-svg name="edit"></icon-svg>
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>

        <pagination :tableInfo="pageParams" @changePage="changePage" />
      </div>
    </div>
    <el-dialog
      :title="$t('devices.aps.communicationRecords')"
      :visible.sync="isVisibleDetailDialog"
      class="communication-records_dialog"
      width="1100px"
      :close-on-click-modal="false"
    >
      <communication-records
        v-if="isVisibleDetailDialog"
        :options="currentItem"
        :coorStateList="coorStateList"
      ></communication-records>
    </el-dialog>

    <el-dialog
      :title="$t('devices.aps.APsInfo')"
      :visible.sync="isVisibleEditeDialog"
      width="600px"
      :close-on-click-modal="false"
    >
      <unit-info
        v-if="isVisibleEditeDialog"
        @handelSubmit="handelSubmit"
        ref="form"
        :formData="currentItem"
      ></unit-info>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="cancel-btn btn"
          size="small"
          @click="isVisibleEditeDialog = false"
          >{{ $t("devices.cancel") }}</el-button
        >
        <el-button
          class="save-btn btn"
          :loading="loading"
          size="small"
          @click="handelSubmit"
          type="primary"
          >{{ $t("devices.save") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { debounce } from "lodash";
import moment from "moment";
import UnitInfo from "./modules/UnitInfo";
import CommunicationRecords from "./modules/CommunicationRecords";
import { commonStore } from "@/store/global-select-store";
import pagination from "@/components/pagination";
import {
  getStoreApList,
  coorUpdate,
  batchDelete,
  getCodeList,
} from "@/libs/api/aps";
import {
  COOR_STATE_CONNECTED,
  COOR_STATE_CONNECTING,
  storeTypeList,
  paginationEnum,
} from "@/libs/enum";
export default {
  name: "ApsIndex",
  components: {
    UnitInfo,
    CommunicationRecords,
    pagination,
  },
  data() {
    return {
      commonStore,
      paginationEnum,
      moment,
      storeTypeList,
      coorStateList: [],
      COOR_STATE_CONNECTED,
      COOR_STATE_CONNECTING,
      isVisibleEditeDialog: false,
      isVisibleDetailDialog: false,
      pageParams: {
        current: 1,
        size: 10,
        total: 0,

        date: [],
        storeType: 2,
        storeName: "",
        remark: "",
        ipAddr: "",
        startDate: "",
        endDate: "",
        coorName: "",
        coorState: "",
        startTags: "",
        endTags: "",
        orders: []
      },
      tableData: [],
      currentItem: {},
      ids: [],
      loading: false,
      tableLoading: false,
    };
  },
  methods: {
    // 表格排序
    handleSort(column, prop, order){
      console.log('表格排序',column, prop, order)
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      
      this.pageParams.orders = arr;
      this.getList()
    },
    changePage() {
      this.getList();
    },
    handleDateChange(val) {
      if (val) {
        this.pageParams.startDate = moment(val[0]).format(
          "YYYY-MM-DD"
        );
        this.pageParams.endDate = moment(val[1]).format("YYYY-MM-DD");
        this.pageParams.date = val;
      } else {
        this.pageParams.startDate = "";
        this.pageParams.endDate = "";
      }
      this.getList();
    },
    handelDeleteAll() {
      const { ids } = this;
      if (!ids.length) {
        this.$message({
          message: this.$t("devices.selectedNoData"),
          type: "warning",
        });
        return;
      }

      this.$confirm(
        "Are you sure you want to reset?",
        this.$t("devices.tips"),
        {
          confirmButtonText: this.$t("devices.sure"),
          cancelButtonText: this.$t("devices.cancel"),
          type: "warning",
        }
      )
        .then(async () => {
          try {
            const params = {
              ids: this.ids.join(","),
            };
            const { data } = await batchDelete(params);
            if (data.state === true) {
              this.$message.success(this.$t("devices.operationSuccess"));
              this.getList();
            } else {
              this.$message.error(data.message);
            }
          } catch (err) {
            const { message } = err;
            this.$message.error(message);
          }
        })
        .catch(() => {});
    },
    handelView(item) {
      this.currentItem = item;
      this.isVisibleDetailDialog = true;
    },
    handelEdit(item) {
      this.currentItem = item;
      this.isVisibleEditeDialog = true;
    },
    handelCancel() {
      this.isVisibleEditeDialog = false;
    },
    async handelSubmit() {
      try {
        this.$refs.form.$refs["ruleForm"].validate(async (valid) => {
          if (valid) {
            const ruleForm =
              this.$refs && this.$refs.form && this.$refs.form.ruleForm
                ? this.$refs.form.ruleForm
                : {};
            const { coorId, coorName, remark } = ruleForm;
            const params = {
              id: coorId,
              coorName,
              remark,
            };
            this.loading = true;
            const { data } = await coorUpdate(params);
            if (data.state === true) {
              this.isVisibleEditeDialog = false;
              this.$message.success(this.$t("devices.saveSuccess"));
              this.getList();
            } else {
              this.$message.error(data.message);
            }
            this.loading = false;
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
        this.loading = false;
      }
    },
    getList: debounce(async function () {
      const _this = this;
      let storeId = localStorage.getItem("storeId");
      const params = {
        // storeId: sessionStorage.getItem("storeId"),
        storeId,
        storeType: _this.pageParams.storeType,
        storeName: _this.pageParams.storeName ? _this.pageParams.storeName.trim() : '',
        remark: _this.pageParams.remark ? _this.pageParams.remark.trim() : '',
        startDate: _this.pageParams.startDate,
        endDate: _this.pageParams.endDate,
        ipAddr: _this.pageParams.ipAddr ? _this.pageParams.ipAddr.trim() : '',
        coorName: _this.pageParams.coorName ? _this.pageParams.coorName.trim() : '',
        coorState: _this.pageParams.coorState,
        startTags: _this.pageParams.startTags,
        endTags: _this.pageParams.endTags,

        current: _this.pageParams.current,
        size: _this.pageParams.size,
        orders: _this.pageParams.orders,
      };

      try {
        _this.tableLoading = true;
        const { data } = await getStoreApList(params);
        if (data.state === true) {
          let dataNew = data.data || {};
          _this.tableData = dataNew.records || [];
          _this.pageParams.total = dataNew.total || 0;
          _this.tableLoading = false;
        } else {
          this.$message.error(data.message);
          _this.tableLoading = false;
        }
      } catch (err) {
        const { message } = err;
        _this.$message.error(message);
        _this.tableLoading = false;
      }
    }, 1000),
    handleSelectionChange(data) {
      let ids = [];
      data.forEach((item) => {
        ids.push(item.coorId);
      });
      this.ids = ids;
    },
    async getCodeList() {
      try {
        const { data } = await getCodeList({ cateId: 9 });
        if (data.state === true) {
          this.coorStateList = data.data || [];
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
  },
  created() {
    commonStore.setInjectCallBack(this.getList);
    this.getList();
    this.getCodeList();
  },
  computed: {
    language() {
      return this.$store.state.appConfig.language;
    },
  },
  watch: {
    language(val) {
      if (val) this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.aps-page {
  .card-bg {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }
  &_top ::v-deep {
    padding: 20px;
    margin-bottom: 20px;
    .el-button {
      width: 180px;
      height: 50px;
      background: #3461ff;
      border-radius: 8px;
      // font-size: 18px;
      color: #ffffff;
      i {
        margin-right: 5px !important;
      }
    }
  }
  &_content {
    &-top {
      padding: 15px;
      font-size: 12px;
      // color: #999999;
      > span {
        margin-right: 10px;
      }
    }
    &-table ::v-deep {
      .el-table
        .el-table__header-wrapper
        .el-table__header
        .is-group
        tr:nth-of-type(2)
        th:nth-of-type(1)
        .cell {
        display: none;
      }
      .action-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        .icon {
          margin: 0 5px;
          color: rgba(47, 59, 97, 1);
          font-size: 17px;
        }
        .icon-info {
          font-size: 20px;
        }
        .icon-info:before {
          content: "";
        }
      }
      .coor-state {
        padding: 3px 18px;
        text-align: center;
        border-radius: 15px;
        display: inline-block;
      }
      .state-connected {
        background: rgba(217, 250, 232, 1);
      }
      .state-connecting {
        background: rgba(193, 233, 255, 1);
      }
    }
  }
  .dialog-footer ::v-deep {
    text-align: center;
    .el-button {
      width: 110px;
      height: 40px;
    }
   
    .btn {
      // border-radius: 10px;
    }
    .el-button.cancel-btn {
      background: #d4d4d4 !important;
      box-shadow: 0px 1px 15px 0px rgba(99, 99, 99, 0.23);
      color: #626262;
    }
    .el-button.save-btn {
      background: #35b871 !important;
      box-shadow: 0px 1px 15px 0px rgba(53, 184, 113, 0.55);
      color: #ffffff;
    }
  }
  .communication-records_dialog ::v-deep {
    .el-dialog__body {
      padding: 30px 20px !important;
    }
  }
}
</style>