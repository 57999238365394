<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    label-width="120px"
    class="demo-ruleForm"
  >
    <el-row>
      <el-col :span="24">
        <el-form-item :label="$t('devices.aps.APName')" prop="coorName">
          <el-input v-model="ruleForm.coorName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item  :label="$t('devices.aps.maAddress')" prop="macAddress">
          <el-input v-model="ruleForm.macAddress" :label="$t('devices.aps.No')" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item  :label="$t('devices.aps.storeName')" prop="storeName">
          <el-input v-model="ruleForm.storeName" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item :label="$t('devices.aps.ip')" prop="ipAddr">
          <el-input  v-model="ruleForm.ipAddr" disabled></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="24">
        <el-form-item  :label="$t('devices.aps.remark')" prop="remark">
          <el-input  :maxlength="100" v-model="ruleForm.remark" type="textarea"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep';
export default {
  props: {
    formData: {
      type: Object,
      require: true,
      default: () => ({})
    }
  },
  data() {
    return {
      rules: {
        coorName: [
          {
            required: true,
            message: this.$t('devices.aps.pleaseEnterAPName'),
            trigger: "blur"
          }
        ],
      },
      ruleForm: {}
    };
  },
  watch: {
    
  },
  created() {
    this.ruleForm = cloneDeep(this.formData)
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
